<template lang="pug">
  v-container
    v-row(
      justify="center"
    )
      v-col(
        cols="12"
        md="10"
      )
        v-card(
          outlined
        )
          v-card-text
            v-row

          v-data-table.clickable(
            :headers="headers"
            no-data-text="No Products Found"
            disable-pagination
            :items="rows"
            @click:row=""
            hide-default-footer
            :items-per-page="-1"
          )
            template(#item.qty="{ item: row }")
              v-text-field(
                outlined
                min="1"
                v-model.number='row.qty'
                :rules='[rules.required]'
                hide-details="auto"
                label='Edit'
                single-line
                type="number"
                dense
                style="width: 60px;"
              )

            template(#item.partNumber="{ item: row }")
              .pn
                h4 {{ row.partNumber }}
                | &nbsp;QTY {{ getPart(row.partNumber) ? getPart(row.partNumber).qty : '' }}
            template(#item.item="{ item: row }")
              v-layout(
                flex
                align-center
              )
                v-img.shrink.mr-2(
                  height="40px"
                  width="40px"
                  :src="`/parts/${row.partNumber}.jpg`"
                )
                h4 {{ getPart(row.partNumber) ? getPart(row.partNumber).desc : ''}}

            template(#body.prepend)
              tr
                td(colspan="100")
                  v-btn(
                    color="primary"
                    block
                    :to="{ name: 'drawers' }"
                  )
                    v-icon(
                      left
                    ) mdi-plus
                    | Add Product
              tr
                td.pa-4(colspan="5")
                  ProductsAutocomplete(
                    @change="addToCart"
                    :shopId="shop"
                  )
            template(#item.actions="{ item: product }")
              v-btn(
                icon
                @click="removeRow(product)"
              )
                v-icon mdi-delete
            //- template(#item.listPrice="{ item: product }")
            //-   span ${{ product.listPrice | round(2) }}
            //- template(#item.costPrice="{ item: product }")
            //-   span ${{ product.costPrice | round(2) }}
            //- template(#item.listSubtotal="{ item: product }")
            //-   span ${{ product.listSubtotal | round(2) }}
            //- template(#item.costSubtotal="{ item: product }")
            //-   span ${{ product.costSubtotal | round(2) }}
        v-col.mb-5
          AdditionalComments
          a(href='https://products.aspclips.com', target='_blank'): p.ma-0 Search our catalog to find additional items you may need www.products.aspclips.com
        v-layout(
          justify-center
          flex-column
        )
          v-card.mb-5(
            min-width="475"
            class="mx-auto"
          )
            v-toolbar(
              color="primary"
              dark
            )
              v-toolbar-title(
              )
                p.ma-0 SALES CONDITIONS/AUTHORIZATION
            v-list-item(
            )
              v-list-item-content(
              )
                v-list-item-title(
                )
                  li.text-caption I am authorized to make/submit an order.
                  li.text-caption There is a 10 item minimum to receive free shipping and avoid a S&amp;H fee.
                  li.text-caption Order confirmation does not guarantee shipment of all items.
                  li.text-caption By checking and signing the box below you agree to ASP Clips “Terms and Conditions”
            v-layout
              template(
                v-slot:default="{ accepted }"
              )
              v-list-item-action.mx-2
                v-checkbox(
                  v-model='accepted'
                  :input-value="accepted"
                )
              v-list-item-content
                v-list-item-title
                  p.my-auto I Accept
        v-layout.mt-5(
          justify-center
        )
          .export.text-center
            v-text-field.mb-5(
              filled
              v-model.trim='storeNumber'
              hide-details="auto"
              label='Store/Center #'
              single-line
              :rules='[rules.required]'
            )
            v-text-field.mb-5(
              filled
              v-model.trim='submittedBy'
              hide-details="auto"
              label='Email Address'
              single-line
              :rules="emailRules"
              required
            )
            ShopsAutocomplete(
              v-if="user.isAdmin || user.isRegionAdmin"
              v-model="shopId"
              :rules="[rules.required]"
            )
            FeathersVuexFormWrapper(
              :item="shop"
            )
              template(v-slot="{ clone, save, reset, isDirty }")
                v-form(
                  @submit.prevent="save()"
                  ref="form"
                  v-model="valid"
                )
                  v-autocomplete(
                    v-if="user.isAdmin || user.isRegionAdmin || user.isShopAdmin"
                    label="Marketing Email Frequency"
                    :items="emailFrequency"
                    v-model="clone.reminderSchedule"
                    :rules="[rules.required]"
                    filled
                  )
                  v-btn.mb-10(
                    type="submit"
                    :loading="isSubmitting"
                    color="primary"
                    @click="submitToASP"
                    :disabled="(!shopId && !user.shop_id) || (submittedBy === null || submittedBy === '' || storeNumber === null || storeNumber === '') || !accepted"
                  ) Submit to ASP
                    v-icon(right) mdi-export

</template>

<script>
import ProductsAutocomplete from '@/components/ProductsAutocomplete'
import AdditionalComments from '@/components/AdditionalComments'
import ShopsAutocomplete from '@/components/ShopsAutocomplete'
import validations from '@/mixins/validationRulesMixin'
import parts from '@/data/parts.json'
import Dialog from '@/components/Dialog'

export default {
  name: 'Cart',
  components: {
    Dialog,
    ShopsAutocomplete,
    AdditionalComments,
    ProductsAutocomplete
  },
  props: {
    cartDrawer: {
      type: Boolean,
      required: false
    }
  },
  mixins: [
    validations
  ],
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    rows () {
      const { OrderRow } = this.$FeathersVuex.api
      const rows = OrderRow.findInStore({ query: { __isTemp: true }, temps: true })
      return rows.data.reverse()
    },
    drawers () {
      return this.data.drawers
    },
    parts () {
      return this.data.parts
    },
    sections () {
      return this.drawers.sections
    },
    shop () {
      const { Shop } = this.$FeathersVuex.api
      const existingRecord = Shop.getFromStore(!this.shopId ? this.user.shop_id : this.shopId)
      if (!existingRecord) {
        return Shop.get(!this.shopId ? this.user.shop_id : this.shopId)
      } else {
        return existingRecord
      }
    },
    emailFrequency () {
      return ['weekly', 'bi-weekly', 'monthly']
    },
    scannedItem (result) {
      const { OrderRow } = this.$FeathersVuex.api
      const { data: items } = OrderRow.findInStore({
        query: { partNumber: result, __isTemp: true },
        temps: true
      })
      return items[0] || null
    }
  },
  destroyed () {
    document.removeEventListener('keyup', this.lzrScanner)
  },
  methods: {
    removeRow (row) {
      row.remove()
    },
    getPart (partNumber) {
      return this.parts.filter(part => part.pn === partNumber)[0]
    },
    async submitToASP () {
      const { SubmitOrder } = this.$FeathersVuex.api
      try {
        this.isSubmitting = true
        const order = new SubmitOrder({ rows: this.rows, submittedBy: this.user.permissions === 'REGION_ADMIN' ? this.submittedBy + ' (DISTRIBUTOR)' : this.submittedBy, comment: this.$store.state.comment, additionalRecipient: this.shop.orderingEmail, storeNumber: this.storeNumber, shop_id: this.shopId || this.user.shop_id })

        await order.save()
        await this.rows.map(row => this.removeRow(row))

        this.$router.push({ name: 'shop-orders', params: { shopId: this.user.shop_id } })

        this.$snackSuccess('Order Submitted')
      } catch (error) {
        this.$snackError(error.message)
      } finally {
        this.dialog = true
        this.isSubmitting = false
      }
    },
    lzrScanner (result) {
      if (/^[A-Za-z0-9]$/.test(result.key)) {
        this.lzrScannedProduct = this.lzrScannedProduct + result.key
      }
      if (result.code === 'Enter' || result.code === 'Tab') {
        this.onDecode(this.lzrScannedProduct.toUpperCase())
        this.lzrScannedProduct = ''
      }

      return result
    },
    addToCart ({ product }) {
      const { OrderRow } = this.$FeathersVuex.api
      const orderRows = this.$store.state['order-rows'].tempsById
      let doesScannedProductExistInCart = false
      for (const key in orderRows) {
        if (orderRows[key].partNumber === product.name) {
          doesScannedProductExistInCart = true
        }
        if (doesScannedProductExistInCart && orderRows[key].partNumber === product.name) {
          orderRows[key].qty++
        }
      }
      if (!doesScannedProductExistInCart) {
        const row = new OrderRow({ qty: 1, partNumber: product.name, drawerLabel: product.drawerSection })
        console.log('row', row)
      }
    },
    onDecode (result) {
      const scannedProduct = this.getProduct(result)
      const drawerSection = this.drawers.map(drawer => drawer.sections.filter(section => section.pn === result && section.drawerLabel ? section.drawerLabel : null))
      this.addToCart({ product: { drawerSection: drawerSection[0].drawerLabel, name: scannedProduct.pn, desc: scannedProduct.desc, img: scannedProduct.img, qty: scannedProduct.qty } })

      // this.dialog = false
    },
    getProduct (partNumber) {
      return this.parts.filter(part => part.pn === partNumber)[0]
    }
  },
  watch: {
    shopId: function (val) {
      // Early return if the route `:id` is 'new'
      const { Shop } = this.$FeathersVuex.api
      if (val) {
        this.shop = Shop.get(val)
        return
      }
      const existingRecord = Shop.getFromStore(val)

      if (!existingRecord) {
        this.shop = Shop.get(val)
      }
    },
    cartDrawer: {
      handler (newVal) {
        if (!newVal) {
          document.addEventListener('keyup', this.lzrScanner)
        } else {
          document.removeEventListener('keyup', this.lzrScanner)
        }
      },
      immediate: true
    }
    // We want the above handler handler to run immediately when the component is created.
    // immediate: true
  },
  data: () => ({
    shopId: null,
    submittedBy: null,
    additionalRecipient: null,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    lzrScannedProduct: '',
    storeNumber: null,
    isSubmitting: false,
    accepted: false,
    data: parts,
    dialog: false,
    comment: null,
    headers: [
      {
        text: 'Qty.',
        value: 'qty'
      },
      {
        text: 'Item',
        value: 'item'
      },
      {
        text: 'Part #',
        value: 'partNumber'
      },
      { text: 'Remove', value: 'actions', sortable: false, align: 'end' }
    ]
  })
}
</script>
