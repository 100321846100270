<template lang="pug">
v-layout(
  flex
  justify-center
)
  v-badge(
    left
    bordered
    color="success"
    :content="items"
    overlap
    :value="items"
  )
    v-btn.cartBtn(
      min-width="100%"
      large
      color="secondary"
      @click.stop='cartDrawer = !cartDrawer'
    )
      v-icon(
        left
      ) mdi-cart
      | Go To Cart

  v-navigation-drawer.cartDrawer(
    app
    v-model="cartDrawer"
    temporary
    right
    width="95%"
  )
    v-layout.ma-5(
      flex
      justify-end
    )
      v-btn(
          large
          color='primary'
          @click.stop='cartDrawer = !cartDrawer'
        )
          v-icon(
            left
          ) mdi-close
          | Close
    Cart(
      :cartDrawer="cartDrawer"
    )

</template>

<script>
import Cart from '@/views/Cart'
const accumulator = (accumulator, currentValue) => accumulator + currentValue
export default {
  name: 'CartButton',
  components: {
    Cart
  },
  data: () => ({
    cartDrawer: false
  }),
  computed: {
    items () {
      const { OrderRow } = this.$FeathersVuex.api
      const rows = OrderRow.findInStore({ query: { __isTemp: true }, temps: true })
      if (!rows.data.length) {
        return 0
      }
      const cartQty = rows.data.map(items => items.qty).reduce(accumulator)
      return cartQty
    }
  }
}
</script>

<style lang="scss" scoped>
  .layout {
    @media only screen and (max-width: 600px) {
      min-width: 100% !important;
    }
  }
  .cartBtn {
    @media only screen and (max-width: 600px) {
      margin: .5rem 0;
    }
  }
</style>
